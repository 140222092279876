import { computed } from 'vue'

import { useRuntimeConfig } from '#app'

import type { PublicRuntimeConfig } from 'nuxt/schema'

export const useFeatureFlag = (
  flagName: keyof PublicRuntimeConfig['flags'],
) => {
  const runtimeConfig = useRuntimeConfig()

  return computed(() => {
    const sessionValue = window.sessionStorage.getItem(`flags.${flagName}`)

    if (sessionValue) {
      return sessionValue === 'true'
    }

    return !!runtimeConfig.public.flags?.[flagName]
  })
}

export const useFeatureFlags = () => {
  const runtimeConfig = useRuntimeConfig()

  return computed<PublicRuntimeConfig['flags']>(() => {
    const flags = { ...runtimeConfig.public.flags }

    ;(Object.keys(flags) as Array<keyof PublicRuntimeConfig['flags']>).forEach(
      (flag) => {
        const sessionValue = window.sessionStorage.getItem(`flags.${flag}`)

        if (sessionValue) {
          flags[flag] = sessionValue === 'true'
        }
      },
    )

    return flags
  })
}
